import { FETCH_CATEGORY_FILTERED_DIAG_WALKIN_TEST, FETCH_CATEGORY_TYPE, FETCH_DIAG_PACKAGES_SUCCESS, FETCH_DIAG_PACKAGE_DETAILS, FETCH_DIAG_WALKIN_MOST_BOOKED_SUCCESS, FETCH_DIAG_WALKIN_TEST_SUCCESS, FETCH_SELECTED_POC, FETCH_SELECTED_SLOT_DETAILS, FETCH_WALKIN_LISTVIEW_ITEM, SET_SELECTED_CATEGORIES, SET_SELECTED_SERVICE_LIST, SET_SELECTED_SLOT_PRICES, SLOT_SELECTED_SERVICE_LIST, FETCH_DIAG_HC_TEST_SUCCESS, FETCH_DIAG_HC_MOST_BOOKED_SUCCESS, FETCH_DIAG_HC_PACKAGES_SUCCESS, SET_HC_SELECTED_CATEGORIES, FETCH_CATEGORY_FILTERED_DIAG_HC_TEST, FETCH_HC_SELECTED_POC, FETCH_DIAG_HC_FILTERED_LIST, FETCH_DIAG_WALKIN_FILTERED_LIST, FETCH_DIAG_PROFILES, FETCH_DIAG_HC_PROFILES, FETCH_IF_DIAG_HOMECOLLECTION, IS_FROM_MAIN_DASHBOARD, IS_SINGLE_POC, HC_FILE_URLS, FETCH_DIAGNOSTIC_CENTRES_LIST, FETCH_FOOTER_DIAGNOSTIC_CENTRES_LIST, FETCH_DIAGNOSTIC_CENTRE_DETAILS, FETCH_STATE_OF_CENTRES_LIST_PAGE, FETCH_HEALTH_CU_SELECTED_CAT, FETCH_HEALTH_CU_PACK_LIST, FETCH_HEALTH_CU_CAT_LIST, SET_SERVICE_TESTS, SET_ALL_SERVICES, WALKIN_CAT, SET_SEL_WALKIN_CAT, HC_TEST_CAT, DIAG_PRES_UPLOADED, DIAG_SPECIAL_TEST_PRES, VDC_TEST_SUGGESTIONS, SELECTED_DEPARTMENT, SERVICE_CARD_DATA, FETCH_POC_LIST, DIAG_SPECIAL_TEST_PRES_DETAILS_UPLOADED_LOCALLY, VDC_TEST_DETAILS, IS_SPLIT_FOR_PACKAGE, IS_SPLIT_FOR_POST_PRANDIAL, FETCH_SELECTED_SLOT_DETAILS2, VDC_PACKAGE_SUGGESTIONS, REGISTERED_USERS, SET_SELECTED_USER_DETAILS } from './diagnostic.types';


export default {

    //common
    setSelectedItem(item: any) {
        return (dispatch: any) => {
            dispatch({ type: FETCH_WALKIN_LISTVIEW_ITEM, payload: item });
        }
    },
    setCategoryType(type: number) {
        return (dispatch: any) => {
            dispatch({ type: FETCH_CATEGORY_TYPE, payload: type });
        }
    },

    setSelectedServiceList(isHomeCollection: boolean, list: Map<number, any>) {
        return (dispatch: any) => {
            dispatch({ type: SET_SELECTED_SERVICE_LIST, payload: { selectedServiceList: list, isHomeCollection: isHomeCollection } });
        }
    },
    setSelectedSlotTime(slotDetails: any) {
        return (dispatch: any) => {
            dispatch({ type: FETCH_SELECTED_SLOT_DETAILS, payload: slotDetails });
        }
    },
    setSelectedSlotTime2(slotDetails: any) {
        return (dispatch: any) => {
            dispatch({ type: FETCH_SELECTED_SLOT_DETAILS2, payload: slotDetails });
        }
    },
    slotBasedServiceList(serviceList: any) {
        return (dispatch: any) => {
            dispatch({ type: SLOT_SELECTED_SERVICE_LIST, payload: serviceList });
        }
    },
    slotBasedServicePrices(prices: any) {
        return (dispatch: any) => {
            dispatch({ type: SET_SELECTED_SLOT_PRICES, payload: prices })
        }
    },

    setDiagPackageDetails(packageDetails: any) {
        return (dispatch: any) => {
            dispatch({ type: FETCH_DIAG_PACKAGE_DETAILS, payload: packageDetails });
        }
    },
    setDiagWalkinTests(testList: any) {
        return (dispatch: any) => {
            dispatch({ type: FETCH_DIAG_WALKIN_TEST_SUCCESS, payload: testList });
        }
    },
    setDiagnosticCollectionType(isHomeCollection: boolean) {
        return (dispatch: any) => {
            dispatch({ type: FETCH_IF_DIAG_HOMECOLLECTION, payload: isHomeCollection });
        }
    },
    setIfNavigationFromMainDashboard(isFromMainDashboard: boolean) {
        return (dispatch: any) => {
            dispatch({ type: IS_FROM_MAIN_DASHBOARD, payload: isFromMainDashboard });
        }
    },
    setIsSinglePOC(isSinglePOC) {
        return (dispatch: any) => {
            dispatch({ type: IS_SINGLE_POC, payload: isSinglePOC });
        }
    },

    //walkin
    setMostBookedDiaWalkinData(mostBookedList: any) {
        return (dispatch: any) => {
            dispatch({ type: FETCH_DIAG_WALKIN_MOST_BOOKED_SUCCESS, payload: mostBookedList });
        }
    },
    setDiagPackages(packageList: any) {
        return (dispatch: any) => {
            dispatch({ type: FETCH_DIAG_PACKAGES_SUCCESS, payload: packageList });
        }
    },
    setSelectedCategoryList(categoryItem: any) {
        return (dispatch: any) => {
            dispatch({ type: SET_SELECTED_CATEGORIES, payload: categoryItem });
        }
    }, setWalkInLVFilteredList(filteredList: any) {
        return (dispatch: any) => {
            dispatch({ type: FETCH_DIAG_WALKIN_FILTERED_LIST, payload: filteredList });
        }
    },
    setSelectedPoc(item: any) {
        return (dispatch: any) => {
            dispatch({ type: FETCH_SELECTED_POC, payload: item });
        }
    },
    setDiagWalkinProfiles(profileList: any) {
        return (dispatch: any) => {
            dispatch({ type: FETCH_DIAG_PROFILES, payload: profileList });
        }
    },
    setTestPocList(pocList: any) {
        return (dispatch: any) => {
            dispatch({ type: FETCH_POC_LIST, payload: pocList });
        }
    },


    //homecollection
    setDiagHomeCollectionTests(testList: any) {
        return (dispatch: any) => {
            dispatch({ type: FETCH_DIAG_HC_TEST_SUCCESS, payload: testList });
        }
    }, setMostBookedDiagHCData(mostBookedList: any) {
        return (dispatch: any) => {
            dispatch({ type: FETCH_DIAG_HC_MOST_BOOKED_SUCCESS, payload: mostBookedList });
        }
    },
    setDiagHCPackages(packageList: any) {
        return (dispatch: any) => {
            dispatch({ type: FETCH_DIAG_HC_PACKAGES_SUCCESS, payload: packageList });
        }
    },
    setHCSelectedCategoryList(categoryItem: any) {
        return (dispatch: any) => {
            dispatch({ type: SET_HC_SELECTED_CATEGORIES, payload: categoryItem });
        }
    },
    setHCListViewFilteredList(filteredList: any) {
        return (dispatch: any) => {
            dispatch({ type: FETCH_DIAG_HC_FILTERED_LIST, payload: filteredList });
        }
    },
    setHCSelectedPoc(item: any) {
        return (dispatch: any) => {
            dispatch({ type: FETCH_HC_SELECTED_POC, payload: item });
        }
    },
    setDiagHCProfiles(profileList: any) {
        return (dispatch: any) => {
            dispatch({ type: FETCH_DIAG_HC_PROFILES, payload: profileList });
        }
    },
    setDiagHCFileUrls(fileList: any) {
        return (dispatch: any) => {
            dispatch({ type: HC_FILE_URLS, payload: fileList });
        }
    },

    // VDC
    setDiagCentresList(centresList: any) {
        return (dispatch: any) => {
            dispatch({ type: FETCH_DIAGNOSTIC_CENTRES_LIST, payload: centresList });
        }
    },
    setFooterDiagCentresList(centresList: any) {
        return (dispatch: any) => {
            dispatch({ type: FETCH_FOOTER_DIAGNOSTIC_CENTRES_LIST, payload: centresList });
        }
    },
    setDiagCentreDetails(centreDetails: any) {
        return (dispatch: any) => {
            dispatch({ type: FETCH_DIAGNOSTIC_CENTRE_DETAILS, payload: centreDetails });
        }
    },
    setDiagCentreListPageState(pageDetails: any) {
        return (dispatch: any) => {
            dispatch({ type: FETCH_STATE_OF_CENTRES_LIST_PAGE, payload: pageDetails });
        }
    },
    // VDC_HealthPackages
    setHealthSelCatList(categoryItem: any) {
        return (dispatch: any) => {
            dispatch({ type: FETCH_HEALTH_CU_SELECTED_CAT, payload: categoryItem });
        }
    },
    setHealthPackList(list: any) {
        return (dispatch: any) => {
            dispatch({ type: FETCH_HEALTH_CU_PACK_LIST, payload: list });
        }
    },
    setHealthCatList(list: any) {
        return (dispatch: any) => {
            dispatch({ type: FETCH_HEALTH_CU_CAT_LIST, payload: list });
        }
    },

    // VDC Services
    setServicesTestList(list: any) {
        return (dispatch: any) => {
            dispatch({ type: SET_SERVICE_TESTS, payload: list });
        }
    },
    setAllServices(list: any) {
        return (dispatch: any) => {
            dispatch({ type: SET_ALL_SERVICES, payload: list });
        }
    },

    // vdc_walkin
    setWalkInCat(list: any) {
        return (dispatch: any) => {
            dispatch({ type: WALKIN_CAT, payload: list });
        }
    },
    setSelWalkinCat(list: any) {
        return (dispatch: any) => {
            dispatch({ type: SET_SEL_WALKIN_CAT, payload: list });
        }
    }, setFilteredList(filteredList: any) {
        return (dispatch: any) => {
            dispatch({ type: FETCH_CATEGORY_FILTERED_DIAG_WALKIN_TEST, payload: filteredList });
        }
    },

    // vdc_home_collection
    setHCTestCat(list: any) {
        return (dispatch: any) => {
            dispatch({ type: HC_TEST_CAT, payload: list });
        }
    }, setHCFilteredList(filteredList: any) {
        return (dispatch: any) => {
            dispatch({ type: FETCH_CATEGORY_FILTERED_DIAG_HC_TEST, payload: filteredList });
        }
    },
    setDiagPrescriptionStatus(status: boolean) {
        return (dispatch: any) => {
            dispatch({ type: DIAG_PRES_UPLOADED, payload: status });
        }
    },

    // vdc common
    setSpecialTestPres(fileUrls: any) {
        return (dispatch: any) => {
            dispatch({ type: DIAG_SPECIAL_TEST_PRES, payload: fileUrls });
        }
    },
    setLocallyUploadedPrescriptionFiles(file: any) {
        return (dispatch: any) => {
            dispatch({ type: DIAG_SPECIAL_TEST_PRES_DETAILS_UPLOADED_LOCALLY, payload: file });
        }
    },
    setTestSugg(suggTests) {
        return (dispatch: any) => {
            dispatch({ type: VDC_TEST_SUGGESTIONS, payload: suggTests });
        }
    },
    setPackageTestSugg(suggTests) {
        return (dispatch: any) => {
            dispatch({ type: VDC_PACKAGE_SUGGESTIONS, payload: suggTests });
        }
    },
    setSelectedDepartment(selectedDepartment) {
        return (dispatch: any) => {
            dispatch({ type: SELECTED_DEPARTMENT, payload: selectedDepartment });
        }
    },
    setServiceCardData(data: any) {
        return (dispatch: any) => {
            dispatch({ type: SERVICE_CARD_DATA, payload: data });
        }
    },
    setTestData(data: any) {
        return (dispatch: any) => {
            dispatch({ type: VDC_TEST_DETAILS, payload: data });
        }
    },
    setSlotTypeAsSplitForPackage(data: any) {
        return (dispatch: any) => {
            dispatch({ type: IS_SPLIT_FOR_PACKAGE, payload: data });
        }
    },
    setSlotTypeAsSplitForPostPrandial(data: any) {
        return (dispatch: any) => {
            dispatch({ type: IS_SPLIT_FOR_POST_PRANDIAL, payload: data });
        }
    },
    setRegisteredUsers(data: any) {
        return (dispatch: any) => {
            dispatch({ type: REGISTERED_USERS, payload: data });
        }
    },
    setSelectedUsersMap(data: any) {
        return (dispatch: any) => {
            dispatch({ type: SET_SELECTED_USER_DETAILS, payload: data });
        }
    }


}
