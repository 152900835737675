import PackageTypes from './package.types';
export default {
    setLoading: () => {
        return (dispatch: any) => {
            dispatch({ type: 'setLoading' });
        };
    },
    setPackageList: (packageList) => {
        return (dispatch: any) => {
            dispatch({ type: PackageTypes.SET_PACKAGE_LIST, payload: packageList });
        };
    },
    setPackageDetails: (packageDetails) => {
        return (dispatch: any) => {
            dispatch({ type: PackageTypes.SET_PACKAGE_DETAILS, payload: packageDetails });
        };
    },

    setdoctorSpecialistsDetails: (doctorSpecialistsOfPackage) => {
        return (dispatch: any) => {
            dispatch({ type: PackageTypes.SET_DOCTOR_SPECIALISTS_LIST, payload: doctorSpecialistsOfPackage });
        };
    },

    setSelectedPackage(selectedPackageDetails) {
        return (dispatch: any) => {
            dispatch({ type: PackageTypes.SELECTED_PACKAGE, payload: selectedPackageDetails });
       }
    },

    setSelectedPersonalDoctorDetails(personalDoctorDetails){
        return (dispatch: any) => {
            dispatch({ type: PackageTypes.SELECTED_PERSONAL_DOCTOR, payload: personalDoctorDetails });
       }
    },

    setCartItemRequestDetails(cartItemRequestDetails){
        return (dispatch: any) => {
            dispatch({ type: PackageTypes.SET_CART_ITEM_DETAILS, payload: cartItemRequestDetails });
       }
    }
}