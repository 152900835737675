
const initialState = { visitedbasepath: '/', lastbasePath: '/' };
export const pagemoduleType = {
    SET_VISITED_BASE_PATH: "SET_VISITED_BASE_PATH",
    UPDATE_HISTORY: "UPDATE_HISTORY",
}
export const pagemoduleActions = {
    setvisitedbasepath(item: any = '/', lastbasePath: any = '/') {
        return (dispatch: any) => {
            dispatch({ type: pagemoduleType.SET_VISITED_BASE_PATH, payload: { visitedbasepath: item, lastbasePath: lastbasePath } });
        }
    },
    updateHistory(path) {
        return (dispatch: any) => {
            dispatch({ type: pagemoduleType.UPDATE_HISTORY, payload: path });
        }
    }
}
export const pagemoduleReducer = (state: any = initialState, action: any) => {
    switch (action.type) {
        case pagemoduleType.SET_VISITED_BASE_PATH:
            return {
                ...state,
                visitedbasepath: action.payload?.visitedbasepath,
                lastbasePath: action.payload?.lastbasePath
            };
        case pagemoduleType.UPDATE_HISTORY:
            let history = state?.history?.length > 0 ? [...state?.history] : [];
            history?.splice(0, 0, action.payload);
            history?.length > 15 ? history?.pop() : '';
            return {
                ...state,
                history: history
            }
        case 'setbasepagemoduleInitialState': //do't touch
            return {
                ...action.payload,
                // visitedbasepath: '/',
                history: []
            }
        default:
            return state;
    }
};