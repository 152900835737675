import LocationDropdwnService from './hs-location.service';
import { connect } from 'react-redux';
import diagnosticActions from '../../diagnostics/diagnostic.actions';
import layoutActions from '../../layout/layout.actions';
import checkoutActions from '../../checkout/checkout.actions';
import { pagemoduleActions } from '../../../base/pagemoduleutil/pagemodule.redux';
import PopCityConst from '../../../../public/vdc/popularCitiesConstants';
import packagesActions from '../../packages/packages.actions';
import StorageUtil from '../../../auth/util/storageUtil';

const initialState = { "selectedLocation": {}, "searchLocationResults": [], "cityLabel": '--Select City--' };
export const hsLocReduxTypes = {
    SET_LOCATION: "SET_LOCATION",
    SET_LOC_SEARCH_RESULT: "SET_LOC_SEARCH_RESULT",
}
export const hsLocAction = {
    setLocation(item: any) {
        item.formatedAddress = LocationDropdwnService?.getFormatedAddress(item);
        StorageUtil.setUnencryptedLocalStorage('userlocation', item);
        LocationDropdwnService.setSerLocation(item);
        return (dispatch: any) => {
            dispatch({ type: hsLocReduxTypes.SET_LOCATION, payload: item });
        }
    },
    setSearchLocList(item: any) {
        return (dispatch: any) => {
            dispatch({ type: hsLocReduxTypes.SET_LOC_SEARCH_RESULT, payload: item });
        }
    }
}
export const hsLocReducer = (state: any = initialState, action: any) => {
    switch (action.type) {
        case hsLocReduxTypes.SET_LOCATION:
            let cityLabel = '';
            if (action?.payload?.areaName) {
                cityLabel = action?.payload?.areaName;
            } else if (action?.payload?.cityName) {
                cityLabel = action?.payload?.cityName;
            } else if (action?.payload?.pinCode) {
                let filteredCity = PopCityConst.popularCity.filter(ele => ele?.pinCode == action.payload.pinCode);
                cityLabel = (filteredCity?.length > 0 && filteredCity[0] ? filteredCity[0].title : action?.payload?.pinCode);
            } else {
                cityLabel = '--Select City--';
            }
            return {
                ...state,
                selectedLocation: action.payload,
                cityLabel: cityLabel,
                searchLocationResults: []
            };
        case hsLocReduxTypes.SET_LOC_SEARCH_RESULT:
            return {
                ...state,
                searchLocationResults: action.payload
            };
        case 'setlocationInitialState': //use this for modifying localstorage save behaviour
            let data = state?.cityLabel != '--Select City--' ? state : {};
            return {
                ...action.payload,
                ...data
            }//`set${reucerNameInCombineReducer}InitialState` is used to set initial state of reducer
        default:
            return state;
    }
};

export const connectLocation = (Component) => {
    return connect(hsLocReducer, { ...hsLocAction, ...pagemoduleActions, ...diagnosticActions, ...layoutActions, ...checkoutActions, ...packagesActions })(Component)
};
