//common
export const FETCH_WALKIN_LISTVIEW_ITEM = 'FETCH_WALKIN_LISTVIEW_ITEM';
export const FETCH_CATEGORY_TYPE = 'FETCH_CATEGORY_TYPE';
export const FETCH_IF_MOST_BOOKED = 'FETCH_IF_MOST_BOOKED';

export const ADD_SELECTED_SERVICE_LIST = "ADD_SELECTED_SERVICE_LIST";
export const SET_SELECTED_SERVICE_LIST = "SET_SELECTED_SERVICE_LIST";
export const SLOT_SELECTED_SERVICE_LIST = "SLOT_SELECTED_SERVICE_LIST";
export const LIST_VIEW_TYPE = "LIST_VIEW_TYPE";
export const DEPARTMENT_ID = "DEPARTMENT_ID";
export const FETCH_DIAG_PACKAGE_DETAILS = "FETCH_DIAG_PACKAGE_DETAILS";
export const DIAG_PACKAGE_SERVICE_ID = "DIAG_PACKAGE_SERVICE_ID";
export const FETCH_SELECTED_SLOT_DETAILS = "FETCH_SELECTED_SLOT_DETAILS";
export const FETCH_SELECTED_SLOT_DETAILS2 = "FETCH_SELECTED_SLOT_DETAILS2";
export const IS_FETCHING = "IS_FETCHING";
export const SET_SELECTED_USER_DETAILS = "SET_SELECTED_USER_DETAILS";
export const SET_SELECTED_SLOT_PRICES = "SET_SELECTED_SLOT_PRICES";

export const FETCH_IF_DIAG_HOMECOLLECTION = "FETCH_IF_DIAG_HOMECOLLECTION"
export const IS_SINGLE_POC = "IS_SINGLE_POC";
export const IS_FROM_MAIN_DASHBOARD = "IS_FROM_MAIN_DASHBOARD";
export const DIAG_PRES_UPLOADED = "DIAG_PRES_UPLOADED";
export const FETCH_POC_LIST = "FETCH_POC_LIST";
export const IS_SPLIT_FOR_PACKAGE = "IS_SPLIT_FOR_PACKAGE";
export const IS_SPLIT_FOR_POST_PRANDIAL = "IS_SPLIT_FOR_POST_PRANDIAL";
export const REGISTERED_USERS = "REGISTERED_USERS";

// VDC common
export const DIAG_SPECIAL_TEST_PRES_DETAILS_UPLOADED_LOCALLY = "DIAG_SPECIAL_TEST_PRES_DETAILS_UPLOADED_LOCALLY"
export const DIAG_SPECIAL_TEST_PRES = "DIAG_SPECIAL_TEST_PRES";

//walkin
export const FETCH_DIAG_WALKIN_TEST_SUCCESS = 'FETCH_DIAG_WALKIN_TEST_SUCCESS';
export const FETCH_DIAG_WALKIN_MOST_BOOKED_SUCCESS = 'FETCH_DIAG_WALKIN_MOST_BOOKED_SUCCESS';
export const FETCH_DIAG_PACKAGES_SUCCESS = 'FETCH_DIAG_PACKAGES_SUCCESS';
export const SET_SELECTED_CATEGORIES = 'SET_SELECTED_CATEGORIES';
export const FETCH_CATEGORY_FILTERED_DIAG_WALKIN_TEST = 'FETCH_CATEGORY_FILTERED_DIAG_WALKIN_TEST';
export const FETCH_SELECTED_POC = "FETCH_SELECTED_POC";
export const FETCH_DIAG_WALKIN_TEST_CATEGORIES_SUCCESS = 'FETCH_DIAG_WALKIN_TEST_CATEGORIES_SUCCESS';
export const FETCH_DIAG_WALKIN_ADVERTISEMENT_LIST_SUCCESS = "FETCH_DIAG_WALKIN_ADVERTISEMENT_LIST_SUCCESS";
export const FETCH_DIAG_WALKIN_FILTERED_LIST = 'FETCH_DIAG_WALKIN_FILTERED_LIST';
export const FETCH_DIAG_PROFILES = 'FETCH_DIAG_PROFILES';

//HomeCollection
export const FETCH_DIAG_HC_TEST_SUCCESS = 'FETCH_DIAG_HC_TEST_SUCCESS';
export const FETCH_DIAG_HC_MOST_BOOKED_SUCCESS = 'FETCH_DIAG_HC_MOST_BOOKED_SUCCESS';
export const FETCH_DIAG_HC_PACKAGES_SUCCESS = 'FETCH_DIAG_HC_PACKAGES_SUCCESS';
export const SET_HC_SELECTED_CATEGORIES = 'SET_HC_SELECTED_CATEGORIES';
export const FETCH_CATEGORY_FILTERED_DIAG_HC_TEST = 'FETCH_CATEGORY_FILTERED_DIAG_HC_TEST';
export const FETCH_HC_SELECTED_POC = "FETCH_HC_SELECTED_POC";
export const FETCH_DIAG_HC_TEST_CATEGORIES = "FETCH_DIAG_HC_TEST_CATEGORIES";
export const FETCH_DIAG_HOME_ADVERTISEMENT_LIST_SUCCESS = "FETCH_DIAG_HOME_ADVERTISEMENT_LIST_SUCCESS";
export const FETCH_DIAG_HC_FILTERED_LIST = 'FETCH_DIAG_HC_FILTERED_LIST';
export const FETCH_DIAG_HC_PROFILES = 'FETCH_DIAG_HC_PROFILES';
export const HC_FILE_URLS = 'HC_FILE_URLS';

//Diagnostic centres 
export const FETCH_DIAGNOSTIC_CENTRES_LIST = 'FETCH_DIAGNOSTIC_CENTRES_LIST';
export const FETCH_FOOTER_DIAGNOSTIC_CENTRES_LIST = 'FETCH_FOOTER_DIAGNOSTIC_CENTRES_LIST';
export const FETCH_DIAGNOSTIC_CENTRE_DETAILS = 'FETCH_DIAGNOSTIC_CENTRE_DETAILS';
export const FETCH_STATE_OF_CENTRES_LIST_PAGE = 'FETCH_STATE_OF_CENTRES_LIST_PAGE';

//health checkup(VDC packages)
export const FETCH_HEALTH_CU_ADV_LIST = 'FETCH_HEALTH_CU_ADV_LIST';
export const FETCH_HEALTH_CU_CAT_LIST = 'FETCH_HEALTH_CU_CAT_LIST';
export const FETCH_HEALTH_CU_PACK_LIST = 'FETCH_HEALTH_CU_PACK_LIST';

export const FETCH_HEALTH_CU_SELECTED_CAT = 'FETCH_HEALTH_CU_SELECTED_CAT';
export const VDC_PACKAGE_SUGGESTIONS = 'VDC_PACKAGE_SUGGESTIONS';

//VDC services
export const SET_SERVICE_TESTS = 'SET_SERVICE_TESTS';
export const SET_ALL_SERVICES = 'SET_ALL_SERVICES';
export const SELECTED_DEPARTMENT = 'SELECTED_DEPARTMENT';
export const SET_VDC_SERVICE_ADVS = 'SET_VDC_SERVICE_ADVS';
export const SERVICE_CARD_DATA = 'SERVICE_CARD_DATA'

// VDC walkin
export const WALKIN_CAT = 'WALKIN_CAT';
export const SET_SEL_WALKIN_CAT = 'SET_SEL_WALKIN_CAT';
export const VDC_TEST_DETAILS = 'VDC_TEST_DETAILS';
export const VDC_TEST_SUGGESTIONS = 'VDC_TEST_SUGGESTIONS';

// VDC HC
export const HC_TEST_CAT = 'HC_TEST_CAT';


