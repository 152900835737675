export default class CheckoutTypes {
    public static FETCH_BASKET_REQUEST = 'FETCH_BASKET_REQUEST';
    public static SET_BASKET_REQUEST = 'SET_BASKET_REQUEST';
    public static UPDATE_BASKET_REQUEST = 'UPDATE_BASKET_REQUEST';
    public static SET_CONFIRMATION_DETAILS = 'SET_CONFIRMATION_DETAILS';
    public static SET_CAN_EDIT_DISCOUNT = 'SET_CAN_EDIT_DISCOUNT';
    public static SET_COUPON = 'SET_COUPON';
    public static IS_BUTTON_ENABLED = 'IS_BUTTON_ENABLED';
    public static SLOT_BOOKING_LIST_COUNT = 'SLOT_BOOKING_LIST_COUNT';
    public static GET_ALL_INVOICES = 'GET_ALL_INVOICES';
}
