import CheckOutTypes from './checkout.types';
import { BasketRequest } from '../../model/basket/basketrequest';

export default {

    setBasketRequest: (basketRequest: BasketRequest) => {
        return (dispatch: any) => {
            dispatch({ type: CheckOutTypes.SET_BASKET_REQUEST, payload: basketRequest });
        };
    },
    setCanEditDiscount: (canEditDiscount: boolean) => {
        return (dispatch: any) => {
            dispatch({ type: CheckOutTypes.SET_CAN_EDIT_DISCOUNT, payload: canEditDiscount });
        };
    },
    setConfirmationDetails: (basketRequest: BasketRequest) => {
        return (dispatch: any) => {
            dispatch({ type: CheckOutTypes.SET_CONFIRMATION_DETAILS, payload: basketRequest });
        };
    },
    setCoupon: (coupon) => {
        return (dispatch: any) => {
            dispatch({ type: CheckOutTypes.SET_COUPON, payload: coupon });
        };
    },
    updateBasketRequest: (basketRequest: BasketRequest) => {
        return (dispatch: any) => {
            dispatch({ type: CheckOutTypes.UPDATE_BASKET_REQUEST, payload: basketRequest });
        };
    },
    setButtonEnableStatus: (enableStatus) => {
        return (dispatch: any) => {
            dispatch({ type: CheckOutTypes.IS_BUTTON_ENABLED, payload: enableStatus })
        }
    },
    setSlotBookingLength: (count) => {
        return (dispatch: any) => {
            dispatch({ type: CheckOutTypes.SLOT_BOOKING_LIST_COUNT, payload: count })
        }
    },
    setAllInvoicesFlag: (getAllInvoices) => {
        return (dispatch: any) => {
            dispatch({ type: CheckOutTypes.GET_ALL_INVOICES, payload: getAllInvoices })
        }
    }

}